import { Stronger } from "components/Stronger";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { useEffect, useState } from "react";
import { converter8DigitosParaData } from "_utils/Data";

export interface IDadosRecall {
  status: string;
  identificador: string;
  dataUltimaAtualizacao: string;
  dataInicio: string;
  dataLimite: string;
  nome: string;
  descricaoDefeito: string;
}

interface Props {
  recalls: IDadosRecall[] | null;
}

export function DadosRecall({ recalls }: Readonly<Props>) {
  const [recallsFiltrados, setRecallsFiltrados] = useState<IDadosRecall[]>([]);
  useEffect(() => {
    setRecallsFiltrados(
      recalls?.filter((recall) => recall.status === "Sucesso") ?? []
    );
  }, [recalls]);

  const temPendencia = recallsFiltrados?.length;

  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone
          icone={recallsFiltrados?.length ? IconePendencia : IconeNadaConsta}
        />
        <Section.Titulo titulo="Recall" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col lg="3">
            <span>Recall</span> <br />
            <Stronger>
              {temPendencia ? "Existe recall pendente" : "Nada consta"}
            </Stronger>
          </Section.Col>
          {temPendencia && (
            <Section.Col lg="3">
              <span>Data de atualização</span> <br />
              <Stronger>
                {recallsFiltrados[0].dataUltimaAtualizacao ?? "-"}
              </Stronger>
            </Section.Col>
          )}
        </Section.Row>

        {recallsFiltrados?.map((recall) =>
          recall.nome && recall.dataInicio ? (
            <>
              <Section.Row>
                <Section.Col>
                  <span>Identificador</span> <br />
                  <Stronger>
                    {recall.identificador}
                    {recall.nome
                      ? ` - ${recall.nome.replace(
                          `${recall.identificador}-`,
                          ""
                        )}`
                      : ""}
                  </Stronger>
                </Section.Col>
                <Section.Col>
                  <span>Data de registro</span> <br />
                  <Stronger>
                    {converter8DigitosParaData(recall.dataInicio)}
                  </Stronger>
                </Section.Col>
              </Section.Row>
              <Section.Row>
                <Section.Col>
                  <span>Descrição do defeito</span> <br />
                  <Stronger>{recall.descricaoDefeito}</Stronger>
                </Section.Col>
              </Section.Row>
            </>
          ) : (
            <></>
          )
        )}
      </Section.Body>
    </Section.Root>
  );
}
