import { formatarDinheiro } from "_utils/Money";
import { DadosArquivosCnab } from "../../Interfaces/DadosVisualizarCnabs";
import {
  ContainerCinzaInfoArquivoCnab,
  ContainerInfoArquivoCnabIcone,
  DivInfoArquivoCnab,
  DivInfoArquivoCnabDataEnvio,
  DivInfoArquivoCnabIcones,
  DivInfoArquivoCnabNomeArquivo,
  IconeArquivoContainer,
} from "../../style";
import { FaEnvelope, FaFileAlt, FaUndo } from "react-icons/fa";
import { IconeStatusCNAB } from "../../../IconeStatusCNAB";
import { IconesEnviarPara } from "../IconesEnviarPara";
import { formataDataISOParaBR } from "_utils/Data";
import { handleStatusCnab } from "../../../../Constants/handleStatusCnab";
import { Tooltip } from "components/Tooltip";
import { InfoTooltip } from "components/InfoTooltip";

interface Props {
  dados: DadosArquivosCnab;
  reenviar: (id: number, nome: string) => void;
  reprocessar: (id: number) => void;
}

const converterStatus = {
  enviado: "Enviado",
  falha: "Falha",
  processando: "Processando",
};

const tooltipReprocessarBottom = 1.3;
const tooltipReprocessarWidth = 142;
const tooltipReprocessarRight = -450;

const tooltipReenviarBottom = 1.3;
const tooltipReenviarWidth = 165;
const tooltipReenviarRight = -1150;

export function InfosArquivoCnab({ dados, reenviar, reprocessar }: Props) {
  return (
    <ContainerCinzaInfoArquivoCnab>
      <DivInfoArquivoCnab
        style={{ width: "5%", display: "flex", alignItems: "center" }}
      >
        <IconeArquivoContainer>
          <FaFileAlt />
        </IconeArquivoContainer>
      </DivInfoArquivoCnab>
      <DivInfoArquivoCnab
        style={{ width: "26%", display: "flex", alignItems: "center" }}
      >
        <div>
          <DivInfoArquivoCnabNomeArquivo>
            {dados.nome}
          </DivInfoArquivoCnabNomeArquivo>
          <DivInfoArquivoCnabDataEnvio>
            Data de envio:{" "}
            {dados.dataEnvio ? formataDataISOParaBR(dados.dataEnvio) : "-"}
          </DivInfoArquivoCnabDataEnvio>
          <DivInfoArquivoCnabIcones>
            <ContainerInfoArquivoCnabIcone
              ativo={handleStatusCnab(dados.status) === "enviado"}
            >
              <Tooltip
                width={tooltipReenviarWidth}
                bottom={tooltipReenviarBottom}
                right={tooltipReenviarRight}
                trigger={
                  <FaEnvelope onClick={() => {
                    if (handleStatusCnab(dados.status) === "enviado") {
                      reenviar(dados.id, dados.nome)}
                    }
                } />
                }
              >
                <InfoTooltip mensagem={"Reenviar e-mail"}></InfoTooltip>
              </Tooltip>
            </ContainerInfoArquivoCnabIcone>
            <ContainerInfoArquivoCnabIcone
              ativo={handleStatusCnab(dados.status) === "falha"}
            >
              <Tooltip
                width={tooltipReprocessarWidth}
                bottom={tooltipReprocessarBottom}
                right={tooltipReprocessarRight}
                trigger={
                  <FaUndo
                    style={{ marginLeft: "16px" }}
                    onClick={() => {
                      if (handleStatusCnab(dados.status) === "falha") {
                        reprocessar(dados.id)
                      }
                    }}
                  />
                }
              >
                <InfoTooltip mensagem={"Reprocessar"}></InfoTooltip>
              </Tooltip>
            </ContainerInfoArquivoCnabIcone>
          </DivInfoArquivoCnabIcones>
        </div>
      </DivInfoArquivoCnab>
      <div style={{ width: "69%" }}>
        <table width="100%">
          <tbody>
            <tr>
              <td width="30%">Qtd de débitos</td>
              <td width="40%">Banco</td>
              <td width="30%">Status</td>
            </tr>
            <tr>
              <td>
                <strong>{dados.debitosEfetivados ?? "-"}</strong>
              </td>
              <td>
                <strong>{dados.nomeBanco ?? "-"}</strong>
              </td>
              <td>
                <strong>
                  <IconeStatusCNAB
                    status={handleStatusCnab(dados.status)}
                    tamanho="8px"
                    margin="4px"
                  />
                  {dados.status
                    ? converterStatus[handleStatusCnab(dados.status)]
                    : "-"}
                </strong>
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: "24px" }}>Valor total</td>
              <td style={{ paddingTop: "24px" }}>Solicitante</td>
              <td style={{ paddingTop: "24px" }}>Enviar para</td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <strong>
                  {dados.montanteEfetivado
                    ? formatarDinheiro(Number(dados.montanteEfetivado))
                    : "-"}
                </strong>
              </td>
              <td style={{ verticalAlign: "top" }}>
                <strong>{dados.nomeUsuarioSolicitante ?? "-"}</strong>
              </td>
              <td>
                <strong>
                  {dados.emails.length ? (
                    <IconesEnviarPara
                      dados={dados.emails}
                      statusCnab={dados.status}
                    />
                  ) : (
                    "-"
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ContainerCinzaInfoArquivoCnab>
  );
}
