import styled from "styled-components";

interface ITooltipContent {
  bottom: number;
  right: number;
  width: number;
  marginLeft?: number;
}

interface ITooltipWrapper {
  positionRelative: boolean;
}

export const TooltipWrapper = styled.div<ITooltipWrapper>`
  ${({ positionRelative }) => (positionRelative ? "position: relative;" : "")}
  display: inline-block;
`;

export const TooltipContent = styled.div<ITooltipContent>`
  visibility: hidden;
  width: ${({ width }) => width}px !important;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 10000000000 !important;
  bottom: ${({ bottom }) => bottom}rem;
  ${({ marginLeft, right }) =>
    marginLeft
      ? `margin-left: ${marginLeft}% !important;`
      : `right: ${right}%;`}
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
`;

export const TooltipTrigger = styled.span`
  &:hover + ${TooltipContent} {
    visibility: visible;
    opacity: 1;
  }
`;
