import { TextoClaro } from "components/TextoClaro";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { ToggleRestricoes } from "./containers/ToggleRestricoes";
import { Stronger } from "components/Stronger";

export interface IDadosRestricoes {
  tipoBloqueio: string;
  numeroProcessoBloqueio: number;
  dataBloqueio: string;
  motivoBloqueio: string;
  orgaoExpedidorBloqueio: string;
  municipioExpedidorBloqueio: string;
  ufOrgaoExpedidorOficioBloqueio: string;
  indicadorComunicacaoDeVenda: string;
  dataComunicacao: string;
  dataVenda: string;
  dataHoraAtualizacao?: string;
}

interface Props {
  restricoes: IDadosRestricoes[];
}

export function DadosRestricoes({ restricoes }: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={restricoes.length ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Restrições" />
      </Section.Header>
      <Section.Body>
        {restricoes.length ? (
          <>
            <Section.Row>
              <Section.Col lg="7" md="7" sm="7">
                <TextoClaro>Descrição</TextoClaro>
              </Section.Col>
              <Section.Col lg="4" md="4" sm="4">
                <TextoClaro>Quantidade</TextoClaro>
              </Section.Col>
            </Section.Row>
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8">
                    Restrições
                  </Section.Col>
                  <Section.Col lg="4" md="4" sm="4">
                    {restricoes.length}
                  </Section.Col>
                </>
              }
              toggleContent={
                restricoes.length
                  ? restricoes.map((restricao) => <ToggleRestricoes key={restricao.numeroProcessoBloqueio} dados={restricao} />)
                  : null
              }
            />
          </>
        ) : (
          <Section.Row>
            <Section.Col>
              <span>Restrições</span> <br />
              <Stronger>Nada consta</Stronger>
            </Section.Col>
          </Section.Row>
        )}
      </Section.Body>
    </Section.Root>
  );
}

