import React, { useCallback, useEffect, useState } from "react";
import {
  AreaClicavel,
  FileInput,
  FileUploadContainer,
  SpanMensagemErro,
  UploadButton,
  UploadIcon,
  UploadTextContainer,
} from "./style";

interface FileUploadProps {
  onFileUpload: (file: File | null) => void;
  mensagemErro?: NullableString;
  limparArquivo?: boolean;
  maxWidth?: string;
  width?: string;
  inputAccept?: string;
}

function FileUpload({
  onFileUpload,
  mensagemErro,
  limparArquivo,
  maxWidth = "350px",
  width = "auto",
  inputAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | null) => {
      if (!event && limparArquivo) {
        setSelectedFile(null);
        return;
      }

      const file = event?.target.files?.[0];

      setSelectedFile(file!);
      onFileUpload(file!);
    },
    [onFileUpload, limparArquivo]
  );

  useEffect(() => {
    if (limparArquivo) {
      handleFileChange(null);
    }
  }, [limparArquivo, handleFileChange]);

  return (
    <FileUploadContainer maxWidth={maxWidth} width={width}>
      <FileInput
        accept={inputAccept}
        id="fileInput"
        type="file"
        onChange={handleFileChange}
      />
      <AreaClicavel htmlFor="fileInput">
        <UploadTextContainer comErro={!!mensagemErro}>
          {selectedFile ? (
            <span>{selectedFile.name}</span>
          ) : (
            <span>Selecione o arquivo</span>
          )}
        </UploadTextContainer>
        <UploadButton comErro={!!mensagemErro}>
          <UploadIcon />
          Upload
        </UploadButton>
      </AreaClicavel>
      {mensagemErro && <SpanMensagemErro>{mensagemErro}</SpanMensagemErro>}
    </FileUploadContainer>
  );
}

export default FileUpload;

