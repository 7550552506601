import { useCallback, useEffect, useState } from "react";
import { useRequest } from "hooks/useRequest";
import { toastErro } from "components/Toast";
import { useNavigate } from "react-router-dom";

export function useExtratoVeicularDetalhado(
  idExtratoVeicular: string | undefined,
  idLoteExtratoVeicular: string | undefined,
  redirect: boolean = true,
  serviceRequisicao: "consulta" | "consultaIntegracao" = "consulta",
  dadosCripto: string | null = null
) {
  const [detalhesExtratoVeicular, setDetalhesExtratoVeicular] =
    useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const { loading, get, post } = useRequest(serviceRequisicao);
  const navigate = useNavigate();

  const buscarDetalhesExtratoVeicular = useCallback(async () => {
    const { data, hasErro } =
      serviceRequisicao === "consulta" && dadosCripto
        ? await post("integracao/extrato/detalhes", { dadosCripto })
        : await get(
            `veiculo/extrato/${idLoteExtratoVeicular}/detalhes/${idExtratoVeicular}`
          );

    if (hasErro) {
      setError(hasErro);
      toastErro("Não foi possível acessar os detalhes");

      if (redirect) {
        navigate(`/veiculos/extrato/consultas/${idLoteExtratoVeicular}`);
      }
      return;
    }

    setDetalhesExtratoVeicular(data);
  }, [idExtratoVeicular, get, navigate, idLoteExtratoVeicular]);

  useEffect(() => {
    buscarDetalhesExtratoVeicular();
  }, [buscarDetalhesExtratoVeicular]);

  return {
    detalhesExtratoVeicular,
    loading,
    error,
  };
}
