import { TextoClaro } from "components/TextoClaro";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { ToggleRestricoes } from "./containers/ToggleRestricoes";
import { Stronger } from "components/Stronger";
import { IDadosRestricoes } from "../DadosRestricoes";

interface Props {
  restricoes: IDadosRestricoes[];
}

export function DadosRestricoesNacional({ restricoes }: Readonly<Props>) {
  const haPendencia = restricoes.length;
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={haPendencia ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Restrições" />
      </Section.Header>
      <Section.Body>
        {haPendencia ? (
          <>
            <Section.Row>
              <Section.Col lg="3">
                <span>Tipo de bloqueio</span>
              </Section.Col>
              <Section.Col lg="3">
                <span>Data de atualização</span>
              </Section.Col>
            </Section.Row>
            {restricoes.map((restricao) => (
              <Section.Row>
                <Section.Col lg="3">
                  <Stronger>{restricao.motivoBloqueio ?? "-"}</Stronger>
                </Section.Col>
                {restricao.dataHoraAtualizacao && (
                  <Section.Col lg="3">
                    <Stronger>{restricao.dataHoraAtualizacao ?? "-"}</Stronger>
                  </Section.Col>
                )}
              </Section.Row>
            ))}
          </>
        ) : (
          <Section.Row>
            <Section.Col>
              <span>Restrições</span> <br />
              <Stronger>Nada consta</Stronger>
            </Section.Col>
          </Section.Row>
        )}
      </Section.Body>
    </Section.Root>
  );
}
