import styled from "styled-components";

interface IContainerItemAdicionarCota {
  exibir: boolean;
}
export const ContainerItemAdicionarCota = styled.div<IContainerItemAdicionarCota>`
  ${({ exibir }) => (exibir ? "" : "display: none;")}
  background-color: #FFF;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 10px 0;
`;
