import styled from "styled-components";
import { FaUpload } from "react-icons/fa";

interface ErrorProps {
  comErro: boolean;
}

interface UploadContainerProps {
  maxWidth: string;
  width?: string;
}

export const FileUploadContainer = styled.div<UploadContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: ${({ width }) => width ?? 'auto'};
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const FileInput = styled.input`
  display: none;
`;

export const UploadButton = styled.div<ErrorProps>`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.35rem 1rem;
  background-color: transparent;
  color: ${({ theme, comErro }) =>
    comErro ? theme.colors.danger.main : theme.colors.primary.main};
  border: 1px solid
    ${({ theme, comErro }) =>
      comErro ? theme.colors.danger.main : theme.colors.primary.main};
  border-radius: 0rem 0.5rem 0.5rem 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 13px;

  &:hover {
    border-color: ${({ theme, comErro }) =>
      comErro ? theme.colors.danger.hover : theme.colors.primary.hover};
    color: ${({ theme, comErro }) =>
      comErro ? theme.colors.danger.hover : theme.colors.primary.hover};
  }

  @media (max-width: 1400px) {
    padding: 0.2rem;

    svg {
      margin: 0.10rem;
    }
  }
`;

export const UploadTextContainer = styled.div<ErrorProps>`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid
    ${({ theme, comErro }) =>
      comErro ? theme.colors.danger.main : theme.colors.neutral.neutral30};
  border-right: none;
  padding: 0.45rem 0.5rem;
  border-radius: 0.5rem 0 0 0.5rem;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral.neutral30};
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    scrollbar-width: none;
  }
`;

export const AreaClicavel = styled.label`
  display: flex;
  width: 100%;
  height: 2.2rem;

  &:hover {
    cursor: pointer;
  }
`;

export const UploadIcon = styled(FaUpload)`
  margin-right: 0.5rem;
`;

export const SpanMensagemErro = styled.span`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.danger.main};
`;

FileUploadContainer.defaultProps = {
  maxWidth: '350px'
}