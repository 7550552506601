import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { DadosProprietarioPDF } from "./containers/DadosProprietario";
import { DadosVeiculoPDF } from "./containers/DadosVeiculo";
import { DadosIpvaPDF } from "./containers/DadosIpva";
import { DadosLicenciamentoPDF } from "./containers/DadosLicenciamento";
import { DadosGravamePDF } from "./containers/DadosGravame";
import { DadosMultasPDF } from "./containers/DadosMultas";
import { STYLES } from "./style";
import logoGrupoLw from "_assets/images/logo-grupo-lw-cinza.png";
import logoLwDebitos from "_assets/images/logo-lw-debitos.png";
import { DadosRestricoesPDF } from "./containers/DadosRestricoes";
import { DadosRecallPDF } from "./containers/DadosRecall";
import { IDetalhesExtratoVeicular } from "../..";
import { DadosLeiloesPDF } from "./containers/DadosLeiloes";
import { DadosIpvaPDFNacional } from "./containers/DadosIpvaNacional";
import { DadosLicenciamentoPDFNacional } from "./containers/DadosLicenciamentoNacional";
import { DadosMultasPDFNacional } from "./containers/DadosMultasNacional";
import { DadosLeiloesPDFNacionalSL } from "./containers/DadosLeiloesNacionalSL";
import { DadosDpvatPDFNacional } from "./containers/DadosDpvatNacional";
import { DadosRestricoesPDFNacional } from "./containers/DadosRestricoesNacional";
import { IDadosRestricoes } from "../DadosRestricoes";
import { FonteRetorno } from "features/extrato-veicular/enuns/FonteRetorno.enum";
import { InformacaopPDF } from "./containers/InformacaoPDF";
import { Data } from "_utils/Data";

interface Props {
  dadosPDF: any;
  mostrarLogo?: boolean;
  mostrarDadosProprietario?: boolean;
  esconderDadosVeiculo?: boolean;
}

export function PdfExtratoVeicular({
  dadosPDF,
  mostrarLogo = true,
  mostrarDadosProprietario = true,
  esconderDadosVeiculo = false,
}: Readonly<Props>) {
  return (
    <Document>
      <Page style={{ padding: 16 }} size="A4">
        <View style={[STYLES.SECTION_ROW, { paddingBottom: 12 }]}>
          <View
            style={[
              STYLES.SECTION_COL_1,
              {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              },
            ]}
          >
            {mostrarLogo && (
              <>
                <Image src={logoLwDebitos} style={{ width: "15%" }} />
              </>
            )}
            <Image src={logoGrupoLw} style={{ width: "15%" }} />
          </View>
        </View>
        <View
          style={[
            STYLES.SECTION_COL_1,
            {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <Text style={STYLES.FONT_SIZE_12}>Extrato Veicular</Text>
        </View>
        <InformacaopPDF />
        {mostrarDadosProprietario && (
          <DadosProprietarioPDF
            cpfCnpjProprietario={dadosPDF.dadosVeiculo[0].cpfCnpjProprietario}
            nomeProprietario={dadosPDF.dadosVeiculo[0].nomeProprietario}
          />
        )}

        <DadosVeiculoPDF dadosVeiculo={dadosPDF.dadosVeiculo[0]} esconderDadosVeiculo={esconderDadosVeiculo} />
        {[FonteRetorno.EXTRATO_PR, `${FonteRetorno.DEBITO_NACIONAL}`].includes(
          dadosPDF.combo.fonteRetorno
        ) && (
          <>
            <DadosIpvaPDF ipvas={dadosPDF.ipvas} />
            <DadosLicenciamentoPDF licenciamentos={dadosPDF.licenciamento} />
            <DadosMultasPDF
              multas={dadosPDF.multas.multas}
              autuacoes={dadosPDF.multas.autuacoes}
              totalAutuacoes={dadosPDF.multas.totalAutuacoes}
              total={dadosPDF.multas.total}
            />
            <DadosGravamePDF gravames={dadosPDF.gravames} />
            <DadosRestricoesPDF
              restricoes={dadosPDF.bloqueios as IDadosRestricoes[]}
            />
          </>
        )}
        {dadosPDF.combo.fonteRetorno === FonteRetorno.EXTRATO_NACIONAL && (
          <>
            <DadosIpvaPDFNacional ipvas={dadosPDF.ipvas} />
            <DadosLicenciamentoPDFNacional
              licenciamentos={dadosPDF.licenciamento}
            />
            <DadosDpvatPDFNacional dpvats={dadosPDF?.dpvats} />
            <DadosMultasPDFNacional
              multas={dadosPDF.multas.multas}
              total={dadosPDF.multas.total}
            />
            <DadosRestricoesPDFNacional
              restricoes={dadosPDF.bloqueios as IDadosRestricoes[]}
            />
          </>
        )}
        <DadosRecallPDF recalls={dadosPDF.recalls} />
        {!dadosPDF.leiloes ? (
          <DadosLeiloesPDFNacionalSL />
        ) : (
          <DadosLeiloesPDF leiloes={dadosPDF.leiloes} />
        )}
        <View
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <View
            style={[
              STYLES.SECTION_COL_1,
              {
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              },
            ]}
          >
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_CLARO]}>
              Data/Hora da Consulta:{" "}
              {new Data(
                dadosPDF.dadosVeiculo[0].dataHoraCadastro
              ).getDataHoraBr()}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
