import { ModalCustom } from "components/ModalCustom";
import {
  BotaoClaroModal,
  BotaoConfirmarModal,
  FormContainer,
  ModalFooter,
} from "./style";
import { useDebitos } from "../../../hook/useDebitos";
import FileUpload from "components/UploadArquivos";
import { toastSucesso } from "components/Toast";
import { useState } from "react";
import { FaDownload } from "react-icons/fa";

interface Props {
  show: boolean;
  handleClose: () => void;
  title: string;
  idConsulta: number;
  setShowModalSucesso: (value: boolean) => void;
}

export function ModalEdicaoDebitosEmLote({
  show,
  handleClose,
  title,
  idConsulta,
  setShowModalSucesso,
}: Props) {
  function downloadModelo() {
    const link = document.createElement("a");
    link.download = "Modelo_Consultar_Manualmente.xlsx";
    link.href = "/files/Modelo_Consultar_Manualmente.xlsx";
    link.click();
  }

  const [arquivo, setArquivo] = useState<File | null>(null);

  const { cadastroManualLote } = useDebitos();

  const handleUploadDeArquivo = async () => {
    if (!arquivo) return;
    const { hasErro } = await cadastroManualLote(arquivo, idConsulta);

    if (!hasErro) {
      setShowModalSucesso(true);
      handleClose();
    }
  };

  return (
    <ModalCustom size={"lg"} show={show} handleClose={handleClose} title={title} centered>
      <FormContainer>
        Realize o upload da planilha com os dados para edição dos débitos.
        <FileUpload
          onFileUpload={(file) => {
            if (!file) return;

            setArquivo(file);
          }}
          width="100%"
          maxWidth="100%"
        />
        <ModalFooter>
          <BotaoClaroModal onClick={handleClose}>Cancelar</BotaoClaroModal>
          <BotaoClaroModal onClick={downloadModelo}>
            Baixar modelo <FaDownload />
          </BotaoClaroModal>
          <BotaoConfirmarModal onClick={handleUploadDeArquivo}>
            Salvar
          </BotaoConfirmarModal>
        </ModalFooter>
      </FormContainer>
    </ModalCustom>
  );
}
