import { CompositeContainer } from "components/CompositeContainer";
import { ModalCustom } from "components/ModalCustom";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { ConsultaManualUnitaria } from "./ConsultaManualUnitaria";
import {
  Container,
  CustomToolTip,
  TooltipApresentacao,
  TooltipAssunto,
  TooltipSubdivisao,
  TooltipWrapper,
} from "./style";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CotaIpva } from "features/debito/consulta/enum/CotaIpva";

interface ConsultaManualProps {
  debito?: IDetalhesConsulta;
  tipo: "UNITARIO" | "LOTE";
  idConsulta?: string;
  dados: IDetalhesConsulta[];
  setDados: (dados: IDetalhesConsulta[]) => void;
  usuarioUltimaAlteracao: string;
  dataUltimaAlteracao: string;
  descricaoDebito: string;
  setShowSucessoModal: (value: boolean) => void;
}

export function ConsultaManual({
  debito,
  tipo,
  idConsulta,
  setDados,
  dados,
  usuarioUltimaAlteracao,
  dataUltimaAlteracao,
  descricaoDebito,
  setShowSucessoModal,
}: Readonly<ConsultaManualProps>) {
  const [showModal, setShowModal] = useState(false);

  const descricoesInvalidas = [
    "Licenciamento já solicitado para pagamento",
    "IPVA já solicitado para pagamento",
    "Débito reconsultado no lote ",
  ];

  function abrirModalManual() {
    setShowModal(true);
  }
  function fecharClose() {
    setShowModal(false);
  }

  const handleContainerDesabilitado = (): boolean => {
    const statusComEditar = [
      StatusSolicitacaoPagamentoEnumV2["Débito indisponível"],
      StatusSolicitacaoPagamentoEnumV2["Nada consta"],
      StatusSolicitacaoPagamentoEnumV2.Falha,
      StatusSolicitacaoPagamentoEnumV2["Dados inconsistentes"],
      StatusSolicitacaoPagamentoEnumV2["Débito disponível"],
    ];
    if (
      (debito?.fonteDeConsulta != "MANUAL" &&
        Number(debito?.statusDebito) ==
          StatusSolicitacaoPagamentoEnumV2["Débito disponível"]) ||
      !statusComEditar.some((status) => Number(debito?.statusDebito) == status)
    ) {
      return true;
    }

    if (debito?.cotaIpva == CotaIpva.Única) return true;

    if (debito?.nomeUsuarioLog) return true;

    if (
      descricaoDebito !== "" &&
      descricoesInvalidas.some((descricao) =>
        descricaoDebito.includes(descricao)
      )
    ) {
      return true;
    }

    return false;
  };

  const bodyStrategy: { [key: string]: React.JSX.Element } = {
    LOTE: <div />,
    UNITARIO: (
      <ConsultaManualUnitaria
        setShowSucessoModal={setShowSucessoModal}
        close={fecharClose}
        consultaId={Number(idConsulta ?? 0)}
        debitosId={debito?.id ?? 0}
        placa={debito?.placa}
        chassi={debito?.chassi}
        renavam={debito?.renavam}
        uf={debito?.uf}
        tipoDebito={debito?.debito}
        setDados={setDados}
        dados={dados}
        disabled={handleContainerDesabilitado()}
        cota={debito?.cota}
        valor={`${debito?.valor}`}
        vencimento={
          debito?.vencimento ? new Date(debito?.vencimento) : undefined
        }
        codigoDeBarra={debito?.codigoDeBarras}
        exercicio={debito?.exercicio}
      />
    ),
  };

  const formatarData = (data: string) => {
    const dataFormatada = new Date(data);
    const dia = dataFormatada.getDate().toString().padStart(2, "0");
    const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, "0");
    return `${dia}/${mes}/${dataFormatada.getFullYear()}`;
  };

  const handleEditDisabilitado = () => {
    if (handleContainerDesabilitado()) return;

    abrirModalManual();
  };

  return (
    <>
      <Container disabled={handleContainerDesabilitado()}>
        {usuarioUltimaAlteracao && (
          <OverlayTrigger
            placement="bottom-start"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <CustomToolTip>
                <TooltipWrapper>
                  <TooltipSubdivisao>
                    <TooltipApresentacao>Editado em:</TooltipApresentacao>
                    <TooltipAssunto>
                      {formatarData(dataUltimaAlteracao)}
                    </TooltipAssunto>
                  </TooltipSubdivisao>
                  <TooltipSubdivisao>
                    <TooltipApresentacao>Usuário:</TooltipApresentacao>
                    <TooltipAssunto>{usuarioUltimaAlteracao}</TooltipAssunto>
                  </TooltipSubdivisao>
                </TooltipWrapper>
              </CustomToolTip>
            }
          >
            <div>
              <FaEdit onClick={handleEditDisabilitado} />
            </div>
          </OverlayTrigger>
        )}
        {!usuarioUltimaAlteracao && <FaEdit onClick={handleEditDisabilitado} />}
      </Container>

      <ModalCustom
        show={showModal}
        handleClose={fecharClose}
        title={`Edição ${tipo === "UNITARIO" ? "unitária" : "lote"}`}
        centered
        size="xl"
      >
        <CompositeContainer.Body>{bodyStrategy[tipo]}</CompositeContainer.Body>
      </ModalCustom>
    </>
  );
}
