import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { BtnContainer } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IBtnGerarCNAB {
    onClick?: () => void;
    ativo: boolean;
}

export function BtnGerarCNAB({
    onClick,
    ativo,
}: Readonly<IBtnGerarCNAB>) {
    return (
        <BtnContainer variant="secondary" active={ativo} onClick={() => {
            if (ativo && onClick) {
                onClick();
            }
        }}>
            <FontAwesomeIcon icon={faFileCirclePlus} /> Gerar arquivo CNAB
        </BtnContainer>
    );
};

