import { ModalCustom } from "components/ModalCustom";
import { BotaoFecharModal, ContainerModal, IconeContainer, ParagrafoModal } from "./style";
import { FaCheck } from "react-icons/fa";

interface Props {
    show: boolean;
    handleClose: () => void;
}

export const SucessoConsultaModal = ({ show, handleClose}: Props) => {
    return (
        <ModalCustom
            title="Editar"
            centered
            show={show}
            handleClose={handleClose}
        >
            <ContainerModal>
            <IconeContainer>
                <FaCheck color="#009933" />
            </IconeContainer>
            <ParagrafoModal>
                Dados alterados com sucesso!
            </ParagrafoModal>
            <BotaoFecharModal onClick={handleClose}>
                Fechar
            </BotaoFecharModal>
            </ContainerModal>
        </ModalCustom>
    )
}