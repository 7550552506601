import React from "react";
import { ExportExcel, Search, Tools, ToolsHeaderTableContainer } from "./style";

interface ToolsExportProps {
  search?: any;
  exportExcel?: any;
  tools?: any;
  toolsWidth?: string;
}
function ToolsHeaderTable({
  exportExcel,
  search,
  tools,
  toolsWidth,
}: ToolsExportProps) {
  return (
    <ToolsHeaderTableContainer>
      <Tools width={toolsWidth}>{tools}</Tools>
      {search && <Search>{search}</Search>}
      <ExportExcel>{exportExcel}</ExportExcel>
    </ToolsHeaderTableContainer>
  );
}
export { ToolsHeaderTable };
