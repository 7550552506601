import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const consultarVeiculoSchma = yup.object().shape({
  arquivo: yup.mixed().required("Arquivo é obrigatório"),
  tipoDebito: yup
    .array()
    .min(1, "Tipo débito é obrigatório")
    .required("Tipo débito é obrigatório"),
  uf: yup.array().min(1, "A UF é obrigatória").required("A UF é obrigatória"),
});

export const consultarVeiculoUnitarioSchema = yup.object().shape({
  placa: yup
    .string()
    .required("A placa é obrigatória")
    .max(8, "Placa inválida")
    .min(7, "Placa inválida"),
  renavam: yup
    .string()
    .required("O renavam é obrigatório")
    .max(11, "Renavam inválido")
    .min(11, "Renavam inválido"),
  uf: yup
    .string()
    .required("A UF é obrigatória")
    .max(2, "UF inválida")
    .min(2, "UF inválida"),
  tipoDebito: yup
    .array()
    .min(1, "Tipo débito é obrigatório")
    .required("Tipo débito é obrigatório"),
});

const regexPlacas = /^[A-Za-z]{3}-?\d[0-9A-Za-z]\d{2}$/;

export const filtrosDetalhesDebitosSchema = yup.object().shape({
  placa: yup
    .string()
    .max(8, "Placa inválida")
    .notRequired()
    .trim()
    .matches(regexPlacas, {
      excludeEmptyString: true,
      message: "Placa inválida",
    }),
});

export const cadastrarDebitosSchema = yup.object().shape({
  placa: yup
    .string()
    .required("A placa é obrigatória")
    .max(8, "Placa inválida")
    .min(7, "Placa inválida"),
  renavam: yup.string().required("O renavam é obrigatório"),
  uf: yup
    .string()
    .required("A UF é obrigatória")
    .max(2, "UF inválida")
    .min(2, "UF inválida"),
  tipoDebito: yup.string().required("Tipo débito é obrigatório"),

  exercicio: yup.string().required("Exercicio é obrigatória"),
  valor: yup
    .string()
    .test(
      "valida-valor",
      "Valor de pagamento inválido",
      (value) => value !== "R$ "
    )
    .required("Valor é obrigatório"),
  vencimento: yup.string().required("Vencimento é obrigatória"),
  cota: yup.number().optional().nullable(),
  linhaDigitavel: yup.string().optional().nullable(),
  adicionarCota: yup.array().optional().nullable(),
});

export const formAdicionarCotaSchema = yup.object().shape({
  vencimento: yup.string().required("Vencimento é obrigatória"),
  valor: yup.string().required("Valor é obrigatório"),
  codigoDeBarras: yup.string().optional().nullable(),
});

export const formEditarCotaSchema = yup.object().shape({
  cota: yup.number().required("Cota é obrigatória"),
  vencimento: yup.string().required("Vencimento é obrigatória"),
  valor: yup.string().required("Valor é obrigatório"),
  codigoDeBarras: yup.string().optional().nullable(),
});
