import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ExtratoVeicularDetalhado } from "../ExtratoVeicularDetalhado";
import "./styles.css";

function DetalhesExtratoExterno() {
  const [searchParams] = useSearchParams();

  const [error, setError] = useState(false);
  const dadosCripto = searchParams.get("dadosCripto");

  if (error) {
    return (
      <div className="contanier-error">
        <h1>Oops! Algo deu errado.</h1>
        <p>Tente novamente mais tarde.</p>

        <button className="button" onClick={() => window.location.reload()}>
          Recarregar Página
        </button>
      </div>
    );
  }

  return (
    <>
      <ExtratoVeicularDetalhado
        mostrarHeader={false}
        redirect={false}
        setError={setError}
        serviceRequisicao="consulta"
        mostrarLogo={false}
        mostrarDadosProprietario={false}
        esconderDadosVeiculo={true}
        dadosCripto={dadosCripto}
      />
    </>
  );
}

export { DetalhesExtratoExterno };
