import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonCuston } from "components/ButtonCuston/ButtonCuston";
import { CustomInput } from "components/CustomInput";
import { CustomDatePickerV2 } from "components/DatePickerV2";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import {
  TipoDebitos,
  TipoDebitosString,
} from "features/debito/consulta/enum/TipoDebitos";
import { ICadastrarDebitos } from "features/debito/consulta/interfaces/ICadastrarDebitos";
import { cadastrarDebitosSchema } from "features/debito/consulta/validate/validator";
import { useMaskInput } from "hooks/useMaskInput";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDebitos } from "../../../hook/useDebitos";
import {
  ButtonAdicionarCota,
  ContainerButtonAdicionarCota,
  ContainerButtonRodapeAdicionarCota,
  ContainerInputsAdicionarCota,
  ContainerTituloTabelaAdicionarCota,
  FormContainer,
  SpanTitle,
  TituloContainerInputsAdicionarCota,
} from "./style";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { FormAdicionarCota } from "./components/FormAdicionarCota";
import { ItemAdicionarCota } from "./components/ItemAdicionarCota";
import {
  IAdicionarCota,
  IFormAdicionarCota,
  IFormEditarCota,
} from "./interface/IAdicionarCota";
import { FaTimes } from "react-icons/fa";
import { FormEditarCota } from "./components/FormEditarCota";
import { Required } from "components/Required";
import { formatarApenasNumerosV2, formatarDinheiro } from "_utils/Money";

interface Props {
  close: Callback;
  consultaId: number;
  debitosId: number;
  placa?: string;
  chassi?: string;
  renavam?: string;
  uf?: string;
  disabled?: boolean;
  tipoDebito?: TipoDebitos;
  dados: IDetalhesConsulta[];
  setDados: (dados: IDetalhesConsulta[]) => void;
  setShowSucessoModal: (value: boolean) => void;
  cota?: number;
  valor?: string;
  vencimento?: Date;
  codigoDeBarra?: string;
  exercicio?: number;
}

export function ConsultaManualUnitaria({
  close,
  consultaId,
  debitosId,
  placa,
  chassi,
  renavam,
  uf,
  tipoDebito,
  setDados,
  dados,
  disabled,
  setShowSucessoModal,
  cota,
  valor,
  vencimento,
  codigoDeBarra,
  exercicio,
}: Props) {
  const { mDinheiroInput, mInputPlaca, mInputRenavam } = useMaskInput();

  const { cadastroManual, loading } = useDebitos();
  const [optionsExercicio, setOptionsExercicio] = useState<IOptions[]>([]);
  const [adicionarCotas, setAdicionarCotas] = useState<IAdicionarCota[]>([]);
  const [exibirAdicionarCota, setExibirAdicionarCota] =
    useState<boolean>(false);
  const [exibirAdicionarCotaRodape, setExibirAdicionarCotaRodape] =
    useState<boolean>(false);
  const [cotaSelecionada, setCotaSelecionada] = useState<number | null>(null);
  const [exercicioSelecionado, setExercicioSelecionado] = useState<
    number | null
  >(null);
  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<ICadastrarDebitos>({
    resolver: yupResolver(cadastrarDebitosSchema),
  });

  async function submitFormulario(dadosForm: ICadastrarDebitos) {
    dadosForm.tipoDebito =
      TipoDebitos[dadosForm.tipoDebito as keyof typeof TipoDebitos];

    const { hasErro } = await cadastroManual({
      ...dadosForm,
      consultaId,
      debitosId,
      adicionarCotas: adicionarCotas.map((cota)=>{return{...cota,valor:`${formatarApenasNumerosV2(cota.valor)}`}}),
    });

    if (!hasErro) {
      setDados(
        dados.map((item: IDetalhesConsulta) => {
          if (item.id === debitosId) {
            const statusConsultando = "0";

            return {
              ...item,
              statusDebito: statusConsultando,
              descricao: "",
              fonteDeConsulta: "MANUAL",
            };
          }
          return item;
        })
      );

      setShowSucessoModal(true);
      close();
    }
  }
  useEffect(() => {
    const anoAtual = new Date().getFullYear();
    reset({
      placa,
      chassi,
      renavam,
      uf,
      tipoDebito: TipoDebitosString.get(tipoDebito),
      exercicio: anoAtual,
    });

    setOptionsExercicio([
      {
        label: `${anoAtual}`,
        value: anoAtual,
      },
      {
        label: `${anoAtual - 1}`,
        value: anoAtual - 1,
      },
      {
        label: `${anoAtual - 2}`,
        value: anoAtual - 2,
      },
    ]);

    if (!adicionarCotas.length) {
      setExibirAdicionarCotaRodape(true);
    }

    if (cota) {
      setCotaSelecionada(cota)
      setValue("cota", cota);
    }
    if (valor) {
      setValue("valor", formatarDinheiro(Number(valor) / 100));
    }
    if (vencimento) {
      setValue("vencimento", vencimento);
    }
    if (codigoDeBarra) {
      setValue("linhaDigitavel", codigoDeBarra);
    }
    if (exercicio) {
      setExercicioSelecionado(exercicio)
      setValue("exercicio", exercicio);
    }
  }, []);

  const onSubmitFormAdicionarCota = (dados: IFormAdicionarCota) => {
    setAdicionarCotas([
      ...adicionarCotas,
      {
        cota: calcularProximaCota(),
        ...dados,
        editar: false,
        exercicio: exercicioSelecionado,
      },
    ]);
    setExibirAdicionarCotaRodape(false);
  };

  const handleDivEditarCotas = (exibir: boolean) => {
    setExibirAdicionarCotaRodape(exibir);
    setExibirAdicionarCota(exibir);
    setAdicionarCotas([]);
  };

  const onDeleteItemAdicionarCota = (cota: number) => {
    setAdicionarCotas(adicionarCotas.filter((item) => item.cota != cota));
  };

  const calcularProximaCota = () => {
    let cota = 0;
    if (cotaSelecionada) {
      cota = cotaSelecionada + 1;
    }

    if (adicionarCotas.length) {
      const maiorCota = adicionarCotas.reduce((max, item) => {
        return item.cota > max ? item.cota : max;
      }, -Infinity);
      cota = maiorCota + 1;
    }

    return cota;
  };

  const onSubmitFormEditarCota = (dados: IFormEditarCota) => {
    setAdicionarCotas(
      adicionarCotas.map((cota) => {
        if (cota.cota != dados.cota) {
          return cota;
        }

        return {
          ...cota,
          valor: dados.valor,
          vencimento: dados.vencimento,
          codigoDeBarras: dados.codigoDeBarras,
          editar: false,
        };
      })
    );
  };

  const handleEditarCota = (cotaEscolhida: number, editar: boolean) => {
    setAdicionarCotas(
      adicionarCotas.map((cota) => {
        if (cota.cota != cotaEscolhida) {
          return cota;
        }

        return {
          ...cota,
          editar,
        };
      })
    );
  };

  useEffect(() => {
    setAdicionarCotas(
      adicionarCotas.map((cota) => {
        return {
          ...cota,
          exercicio: exercicioSelecionado,
        };
      })
    );
  }, [exercicioSelecionado]);

  return (
    <FormContainer>
      <SpanTitle>Dados do veículo</SpanTitle>
      <Form noValidate onSubmit={handleSubmit(submitFormulario)}>
        <Row className="mt-4 mb-5">
          <Col>
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe a placa"
                {...register("placa")}
                isValid={isSubmitted && !errors.placa}
                isInvalid={!!errors.placa}
                onChange={mInputPlaca}
                mensagemErro={errors.placa?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Renavam</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe o renavam"
                {...register("renavam")}
                isValid={isSubmitted && !errors.uf}
                isInvalid={!!errors.renavam}
                onChange={mInputRenavam}
                mensagemErro={errors.renavam?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>UF</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe UF"
                {...register("uf")}
                isValid={isSubmitted && !errors.uf}
                isInvalid={!!errors.uf}
                mensagemErro={errors.uf?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
        </Row>
        <SpanTitle>Dados do débito</SpanTitle>
        <Row className="mt-4">
          <Col>
            <Form.Group>
              <Form.Label>Debito</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe o tipo debitos"
                {...register("tipoDebito")}
                isValid={isSubmitted && !errors.tipoDebito}
                isInvalid={!!errors.tipoDebito}
                mensagemErro={errors.tipoDebito?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Cota IPVA</Form.Label>
              <CustomInput
                type="text"
                placeholder="Informe a cota"
                {...register("cota")}
                isValid={isSubmitted && !errors.valor}
                isInvalid={!!errors.valor}
                mensagemErro={errors.valor?.message}
                style={{ height: "2.2rem" }}
                onChange={(e) => {
                  if (!e.target.value) {
                    setCotaSelecionada(null);
                    return;
                  }
                  setCotaSelecionada(Number(e.target.value));
                }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Vencimento</Form.Label>
              <Controller
                control={control}
                name="vencimento"
                render={({ field: { onChange, value } }) => (
                  <CustomDatePickerV2
                    onChange={onChange}
                    selected={value}
                    placeholder="Informe vencimento"
                    mensagemErro={errors.vencimento?.message}
                    isValid={isSubmitted && !errors.vencimento}
                    isInvalid={!!errors.vencimento}
                    isClearable={false}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Valor total</Form.Label>
              <CustomInput
                type="text"
                placeholder="Informe valor"
                {...register("valor")}
                isValid={isSubmitted && !errors.valor}
                isInvalid={!!errors.valor}
                mensagemErro={errors.valor?.message}
                style={{ height: "2.2rem" }}
                onChange={mDinheiroInput}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Exercício</Form.Label>

              <Controller
                control={control}
                name="exercicio"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    onSelect={(ano) => {
                      onChange(ano);
                      if (!ano) {
                        setExercicioSelecionado(null);
                        return;
                      }
                      setExercicioSelecionado(Number(ano));
                    }}
                    disabled={disabled}
                    options={optionsExercicio}
                    selectedValue={value}
                    mensagemErro={errors.exercicio?.message}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Código de barras</Form.Label>
              <CustomInput
                type="text"
                placeholder="Informe código de barras"
                {...register("linhaDigitavel")}
                isValid={isSubmitted && !errors.linhaDigitavel}
                isInvalid={!!errors.linhaDigitavel}
                mensagemErro={errors.linhaDigitavel?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-2 mt-4">
          <Col>
            <ContainerButtonAdicionarCota
              exibir={!exibirAdicionarCota && !!cotaSelecionada}
            >
              <ButtonAdicionarCota onClick={() => handleDivEditarCotas(true)}>
                + Adicionar cota
              </ButtonAdicionarCota>
            </ContainerButtonAdicionarCota>
            <ContainerInputsAdicionarCota exibir={exibirAdicionarCota}>
              <TituloContainerInputsAdicionarCota>
                Editar cotas
                <FaTimes
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDivEditarCotas(false)}
                />
              </TituloContainerInputsAdicionarCota>
              <ContainerTituloTabelaAdicionarCota>
                <Row>
                  <Col md={1}>Cota</Col>
                  <Col md={2}>
                    Vencimento <Required />
                  </Col>
                  <Col md={2}>
                    Valor <Required />
                  </Col>
                  <Col md={6}>Código de barras</Col>
                  <Col md={1}></Col>
                </Row>
              </ContainerTituloTabelaAdicionarCota>
              <div>
                {adicionarCotas.map((cota) => {
                  if (cota.editar) {
                    return (
                      <FormEditarCota
                        cota={cota.cota}
                        codigoDeBarras={cota.codigoDeBarras}
                        vencimento={cota.vencimento}
                        valor={cota.valor}
                        onSubmit={onSubmitFormEditarCota}
                        onCancel={() => handleEditarCota(cota.cota, false)}
                      />
                    );
                  }
                  return (
                    <ItemAdicionarCota
                      cota={cota.cota}
                      valor={cota.valor}
                      vencimento={cota.vencimento}
                      codigoDeBarras={cota.codigoDeBarras}
                      onDelete={onDeleteItemAdicionarCota}
                      onEdit={() => handleEditarCota(cota.cota, true)}
                    />
                  );
                })}
                <FormAdicionarCota
                  cota={calcularProximaCota()}
                  exibir={exibirAdicionarCotaRodape}
                  onSubmit={onSubmitFormAdicionarCota}
                  onCancel={() => setExibirAdicionarCotaRodape(false)}
                />
              </div>
              <ContainerButtonRodapeAdicionarCota>
                <ButtonAdicionarCota
                  onClick={() => setExibirAdicionarCotaRodape(true)}
                >
                  + Adicionar cota
                </ButtonAdicionarCota>
              </ContainerButtonRodapeAdicionarCota>
            </ContainerInputsAdicionarCota>
          </Col>
        </Row>
        <div className="mt-4 mb-3 d-flex justify-content-end gap-4">
          <ButtonCuston variant="primary" onClick={close} outline={true}>
            Cancelar
          </ButtonCuston>
          <ButtonCuston variant="primary" loading={loading}>
            Salvar
          </ButtonCuston>
        </div>
      </Form>
    </FormContainer>
  );
}
