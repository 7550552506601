import { Tooltip } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  disabled: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  overflow: hidden;


  svg {
    width: 1.00rem;
    height: 1.00rem;
    color: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.neutral.neutral50
        : theme.colors.neutral.neutral90};
 
    overflow: hidden;
    ${({ disabled }) => (disabled ? "" : "cursor: pointer;")}
`;

export const CustomToolTip = styled(Tooltip)`
  .tooltip-inner {
    max-width: 400px;
    left: -10px;
    display: flex;
    word-break: break-all;
    padding: 8px;
    background-color: #333333;
  }

  .tooltip {
    opacity: 1;
  }

  .tooltip-arrow {
    display: none !important;
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: fit-content;
`;

export const TooltipApresentacao = styled.span`
  font-size: 14px;
  font-family: "Roboto";
  margin-right: 5px;
`;

export const TooltipAssunto = styled.span`
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 700;
`;

export const TooltipSubdivisao = styled.div`
  display: flex;
`;
