import { Col, Row } from "react-bootstrap";
import { ContainerItemAdicionarCota } from "./style";
import { FaEdit, FaTrash } from "react-icons/fa";
import { converterDataBr } from "_utils/Data";

interface IProps {
  cota: number;
  valor: string;
  vencimento: Date;
  codigoDeBarras?: string;
  onDelete: (cota: number) => void;
  onEdit: (cota: number) => void;
}
export function ItemAdicionarCota({
  cota,
  valor,
  vencimento,
  codigoDeBarras,
  onDelete,
  onEdit,
}: IProps) {
  return (
    <ContainerItemAdicionarCota>
      <Row>
        <Col md={1}>{cota}</Col>
        <Col md={2}>{converterDataBr(vencimento)}</Col>
        <Col md={2}>{valor}</Col>
        <Col md={6}>{codigoDeBarras ?? ""}</Col>
        <Col md={1}>
          <FaEdit
            style={{ cursor: "pointer", marginRight: "15px" }}
            onClick={() => onEdit(cota)}
          />
          <FaTrash
            style={{ cursor: "pointer" }}
            onClick={() => onDelete(cota)}
          />
        </Col>
      </Row>
    </ContainerItemAdicionarCota>
  );
}
