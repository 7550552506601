import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { IDadosRecall } from "../../../DadosRecall";
import { useEffect, useState } from "react";
import { converter8DigitosParaData } from "_utils/Data";

interface Props {
  recalls: IDadosRecall[] | null;
}

export function DadosRecallPDF({ recalls }: Readonly<Props>) {
  const [recallsFiltrados, setRecallsFiltrados] = useState<typeof recalls>([]);
  useEffect(() => {
    setRecallsFiltrados(
      recalls?.filter((recall) => recall.status === "Sucesso") ?? []
    );
  }, [recalls]);
  const temPendencia = recallsFiltrados?.length;

  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.FONT_SIZE_12, STYLES.TEXTO_CLARO]}>Recall</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_1}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
              Recall
            </Text>
            <Text style={STYLES.FONT_SIZE_08}>
              {temPendencia ? "Existe recall pendente" : "Nada consta"}
            </Text>
          </View>
          {recallsFiltrados?.map((recall) =>
            recall.nome && recall.dataInicio ? (
              <View key={recall.identificador}>
                <View style={STYLES.SECTION_ROW}>
                  <View style={STYLES.SECTION_COL_2}>
                    <Text
                      style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}
                    >
                      Identificador
                    </Text>
                    <Text style={STYLES.FONT_SIZE_09}>
                      {recall.identificador}
                      {recall.nome
                        ? ` - ${recall.nome.replace(
                            `${recall.identificador}-`,
                            ""
                          )}`
                        : ""}
                    </Text>
                  </View>
                  <View style={STYLES.SECTION_COL_2}>
                    <Text
                      style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}
                    >
                      Data de registro
                    </Text>
                    <Text style={STYLES.FONT_SIZE_09}>
                      {converter8DigitosParaData(recall.dataInicio)}
                    </Text>
                  </View>
                </View>
                <View style={STYLES.SECTION_ROW}>
                  <View style={STYLES.SECTION_COL_1}>
                    <Text
                      style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}
                    >
                      Descrição do defeito
                    </Text>
                    <Text style={STYLES.FONT_SIZE_09}>
                      {recall.descricaoDefeito}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <></>
            )
          )}
        </View>
      </View>
    </View>
  );
}
