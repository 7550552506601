import { intlCurrencyFormat } from "_utils/Money";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { Stronger } from "components/Stronger";
import { TextoClaro } from "components/TextoClaro";
import { IMultas } from "../DadosMultas";
import { BodyMultas } from "../DadosMultas/containers/BodyMultas";

export interface IDetalhesMultas {
  valor: number;
  descricaoArtigo: string;
  dataInfracao: string;
  horaInfracao: string;
  autoInfracao: string;
  dataVencimento: string;
  situacao: string;
  endereco: string;
  orgao: string;
  valorOriginal: number;
  valorComDesconto: number;
  valorJuro: number;
}

export interface IMultasNacional {
  multasDetran: IDetalhesMultas[];
  multasCetesb: IDetalhesMultas[];
  multasMunicipais: IDetalhesMultas[];
  multasRenainf: IDetalhesMultas[];
  multasDersa: IDetalhesMultas[];
  multasDer: IDetalhesMultas[];
  multasPfr: IDetalhesMultas[];
  multas: IDetalhesMultas[];
  impostas: IDetalhesMultas[];
  notificadas: IDetalhesMultas[];
}

export interface DadosMultasProps {
  total: number;
  multas: IMultasNacional | null;
}
export interface ITiposMultaEscopo {
  label: string;
  atributo:
    | "multasDetran"
    | "multasCetesb"
    | "multasMunicipais"
    | "multasRenainf"
    | "multasDersa"
    | "multasDer"
    | "multasPfr"
    | "multas"
    | "impostas"
    | "notificadas";
}

export function DadosMultasNacional({
  multas,
  total,
}: Readonly<DadosMultasProps>) {
  function existeDebitos(dados: any) {
    const temDebitos = Object.keys(dados).some(
      (key) => Array.isArray(dados[key]) && dados[key].length > 0
    );

    return temDebitos;
  }

  function validarTipoMulta(multas: any, tipoMulta: string) {
    return Object.hasOwn(multas, tipoMulta);
  }

  const tiposMulta: ITiposMultaEscopo[] = [
    { label: "Multas Detran", atributo: "multasDetran" },
    { label: "Multas Cetesb", atributo: "multasCetesb" },
    { label: "Multas municipais", atributo: "multasMunicipais" },
    { label: "Multas RenaInf", atributo: "multasRenainf" },
    { label: "Multas Dersa", atributo: "multasDersa" },
    { label: "Multas DER", atributo: "multasDer" },
    { label: "Multas PFR", atributo: "multasPfr" },
    { label: "Multas (Outros)", atributo: "multas" },
    { label: "Multas notificadas", atributo: "impostas" },
    { label: "Multas notificadas", atributo: "notificadas" },
  ];

  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone
          icone={existeDebitos(multas) ? IconePendencia : IconeNadaConsta}
        />
        <Section.Titulo titulo="Multa" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col lg="7" md="7" sm="7">
            <span>Multas</span> <br />
            <Stronger>
              {existeDebitos(multas)
                ? "Existe débito de Multas"
                : "Não existe débito de Multas"}
            </Stronger>
          </Section.Col>
        </Section.Row>
        {multas && existeDebitos(multas) && (
          <>
            <Section.Row>
              <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                <TextoClaro>Descrição</TextoClaro>
              </Section.Col>
            </Section.Row>
            {tiposMulta.map(({ label, atributo }) => {
              const multasComBaseNoTipo = multas[atributo];
              if (validarTipoMulta(multas, atributo)) {
                if (multasComBaseNoTipo[0]?.autoInfracao) {
                  return (
                    <Section.RowToggle
                      rowContent={
                        <>
                          <Section.Col
                            lg="8"
                            md="8"
                            sm="8"
                            style={{ margin: 0 }}
                          >
                            {label}
                          </Section.Col>
                          <Section.Col
                            lg="2"
                            md="2"
                            sm="2"
                            style={{ margin: 0 }}
                          >
                            {multasComBaseNoTipo?.length ?? 0}
                          </Section.Col>
                          <Section.Col
                            lg="2"
                            md="2"
                            sm="2"
                            style={{ margin: 0 }}
                          >
                            {intlCurrencyFormat(
                              multasComBaseNoTipo
                                ? multasComBaseNoTipo
                                    ?.map((multa) =>
                                      multa.valorComDesconto
                                        ? multa.valorComDesconto
                                        : multa.valor + multa.valorJuro
                                    )
                                    .reduce((acc, cur) => acc + cur / 100, 0)
                                : 0
                            )}
                          </Section.Col>
                        </>
                      }
                      toggleContent={
                        multasComBaseNoTipo?.length ? (
                          <BodyMultas detalhes={multasComBaseNoTipo} />
                        ) : null
                      }
                    />
                  );
                }
                return (
                  <Section.Row
                    style={{
                      backgroundColor: "#E6E6E6",
                      borderRadius: "0.75rem",
                      marginTop: "0.5rem",
                      padding: "0.5rem 0.25rem",
                    }}
                  >
                    <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                      {label}
                    </Section.Col>
                  </Section.Row>
                );
              }
            })}
          </>
        )}
      </Section.Body>
    </Section.Root>
  );
}
