import { Col, Form, Row } from "react-bootstrap";
import { ContainerItemAdicionarCota } from "./style";
import { CustomInput } from "components/CustomInput";
import { useMaskInput } from "hooks/useMaskInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFormEditarCota } from "../../interface/IAdicionarCota";
import { formEditarCotaSchema } from "features/debito/consulta/validate/validator";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useEffect } from "react";
import { CustomDatePickerV2 } from "components/DatePickerV2";

interface IProps {
  cota: number;
  valor: string;
  vencimento: Date;
  codigoDeBarras?: string;
  onSubmit: (dados: IFormEditarCota) => void;
  onCancel: () => void;
}
export function FormEditarCota({
  cota,
  onSubmit,
  onCancel,
  valor,
  vencimento,
  codigoDeBarras,
}: IProps) {
  const { mDinheiroInput, mInputNum } = useMaskInput();

  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<IFormEditarCota>({
    resolver: yupResolver(formEditarCotaSchema),
  });

  useEffect(() => {
    setValue("cota", cota);
    setValue("valor", valor);
    setValue("vencimento", new Date(vencimento));
    setValue("codigoDeBarras", codigoDeBarras ?? "");
  }, []);

  return (
    <ContainerItemAdicionarCota>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={1}>{cota}</Col>
          <Col md={2}>
            <Controller
              control={control}
              name="vencimento"
              render={({ field: { onChange, value } }) => (
                <CustomDatePickerV2
                  onChange={onChange}
                  selected={value}
                  mensagemErro={errors.vencimento?.message}
                  isValid={isSubmitted && !errors.vencimento}
                  isInvalid={!!errors.vencimento}
                  isClearable={false}
                  placeholder=""
                />
              )}
            />
          </Col>
          <Col md={2}>
            <CustomInput
              type="text"
              style={{ height: "2.2rem" }}
              isValid={isSubmitted && !errors.valor}
              isInvalid={!!errors.valor}
              mensagemErro={errors.valor?.message}
              {...register("valor")}
              onChange={mDinheiroInput}
            />
          </Col>
          <Col md={6}>
            <CustomInput
              type="text"
              style={{ height: "2.2rem" }}
              {...register("codigoDeBarras")}
              onChange={mInputNum}
            />
          </Col>
          <Col md={1}>
            <FaCheck
              style={{ cursor: "pointer", marginRight: "15px" }}
              onClick={handleSubmit(onSubmit)}
            />
            <FaTimes style={{ cursor: "pointer" }} onClick={onCancel} />
          </Col>
        </Row>
      </Form>
    </ContainerItemAdicionarCota>
  );
}
