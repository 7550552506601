import styled from "styled-components";

export const ContainerModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;

export const IconeContainer = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  background-color: #E7F5EB;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const ParagrafoModal = styled.p`
    font-size: 14px;
    color: #1A1A1A;
    font-family: Roboto;
    margin: 0;
`;

export const BotaoFecharModal = styled.button`
    background-color: #333333;
    padding: 8px 16px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    font-family: Roboto;
`;
