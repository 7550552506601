import { Button, Col } from "react-bootstrap";
import styled from "styled-components";

export const InputContainer = styled(Col)`
  width: 20%;
  display: grid;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 15px;
    }
  }
`;

export const CustomButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 0.5rem;
  align-self: center;
  margin-bottom: 1.7rem;
  width: 110px;
  height: 36px;
  font-weight: 600;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.hover};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

export const BotaoClaroModal = styled.div`
  border: 1px solid #333333;
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 5px;
  min-height: 32px;
  color: #333333;
  font-family: "Roboto";
  font-size: 14px;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BotaoConfirmarModal = styled.div`
  background-color: #333333;
  padding: 8px 16px;
  border-radius: 5px;
  min-height: 32px;
  color: #ffffff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
